<template>
  <div class="bg-white shadow p-3">
    <div class="text-center fw-bold mb-3">Bergabung dengan kami! Sekarang</div>
    <form @submit.prevent="Register">
      <div class="row">
        <div class="col-md-4 col-lg-4">
          <div class="mb-3">
            <label for="registerName" class="form-label">Nama Lengkap</label>
            <input type="text" class="form-control" id="registerName" v-model="form.name" required />
          </div>
        </div>

        <div class="col-md-4 col-lg-4">
          <div class="mb-3">
            <label for="registerPena" class="form-label">Nama Pena</label>
            <input type="text" class="form-control" id="registerPena" v-model="form.pena" required />
          </div>
        </div>

        <div class="col-md-4 col-lg-4">
          <div class="mb-3">
            <label for="registerPhone" class="form-label">Nomor Kontak</label>
            <input type="text" class="form-control" id="registerPhone" v-model="form.kontak" required />
          </div>
        </div>

        <div class="col-md-4 col-lg-4">
          <div class="mb-3">
            <label for="registerEmail" class="form-label">Email</label>
            <input type="email" class="form-control" id="registerEmail" v-model="form.email" required />
          </div>
        </div>

        <div class="col-md-4 col-lg-4">
          <div class="mb-3">
            <label for="registerPassword" class="form-label">Password</label>
            <input type="password" class="form-control" id="registerPassword" v-model="form.password" required />
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="mb-3">
            <label for="registerConfirmPassword" class="form-label">Konfirmasi Password</label>
            <input type="password" class="form-control" id="registerConfirmPassword" v-model="form.password_confirmation"
              required />
          </div>
        </div>
      </div>

      <div class="fw-bold mb-3">Data Alamat Anda</div>
      <div class="row">
        <!-- alamat select -->
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <label for="registerProvince" class="form-label">Provinsi</label>
            <select id="registerProvince" class="form-control" v-model="form.province" @change="onProvinceChange" required>
              <option value="" disabled selected>Pilih Provinsi</option>
              <option v-for="prov in provinces" :key="prov.id" :value="prov.id">{{ prov.name }}</option>
            </select>
          </div>
          <div class="col-md-6 col-lg-6">
            <label for="registerKota" class="form-label">Kota</label>
            <select id="registerKota" class="form-control" v-model="form.city" @change="onCityChange" :disabled="!cities.length" required>
              <option value="" disabled selected>Pilih Kota</option>
              <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <label for="registerKecamatan" class="form-label">Kecamatan</label>
            <select id="registerKecamatan" class="form-control" v-model="form.district" @change="onDistrictChange" :disabled="!districts.length" required>
              <option value="" disabled selected>Pilih Kecamatan</option>
              <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}</option>
            </select>
          </div>
          <div class="col-md-6 col-lg-6">
            <label for="registerKelurahan" class="form-label">Kelurahan</label>
            <select id="registerKelurahan" class="form-control" v-model="form.village" :disabled="!villages.length" required>
              <option value="" disabled selected>Pilih Kelurahan</option>
              <option v-for="village in villages" :key="village.id" :value="village.id">{{ village.name }}</option>
            </select>
          </div>
        </div>

        <!-- alamat detail -->
        <div class="col-md-12 col-lg-12">
          <div class="mb-3">
            <label for="registerAddress" class="form-label">Alamat Lengkap</label>
            <textarea 
              class="form-control" 
              id="registerAddress" 
              v-model="form.address"
              :class="{ 'is-invalid' : !isAddressValid }"
              required 
              placeholder="Sertakan nama jalan, rt, rw, kode POS, dan no rumah" 
            />
            <div v-if="!isAddressValid" class="invalid-feedback">
              Alamat lengkap harus mencantumkan Jalan, RT, dan RW.
            </div>
          </div>
        </div>
      </div>

      <div class="fw-bold mb-3">Akun Sosial Media Anda</div>
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <div class="input-group mb-3">
            <span class="input-group-text" id="facebook"><i class="bi bi-facebook"></i></span>
            <input type="text" class="form-control" aria-describedby="facebook" v-model="form.facebook" />
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <div class="input-group mb-3">
            <span class="input-group-text" id="instagram"><i class="bi bi-instagram"></i></span>
            <input type="text" class="form-control" aria-describedby="instagram" v-model="form.instagram" />
          </div>
        </div>
      </div>

      <div class="login-btn text-end mb-3">
        <button type="submit" class="btn btn-sm">Daftar</button>
      </div>
    </form>

    <div class="side-menu-item text-center">
      <a href="/login">
        <span class="ms-3">Sudah punya akun? Masuk Sekarang</span>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      form: {
        name: "",
        pena: "",
        kontak: "",
        email: "",
        password: "",
        password_confirmation: "",
        province: "",
        city: "",
        district: "",
        village: "",
        address: "",
        facebook: "",
        instagram: "",
      },
      provinces: [],
      cities: [],
      districts: [],
      villages: [],
    };
  },

  created() {
    this.fetchProvinces();
  },

  metaInfo() {
    return {
      title: 'Daftar',
      meta: [
        { name: 'description', content: 'Penerbit buku online gratis, anti ribet, dan cepat, puluhan ribu naskah sudah terbit disini, sekarang giliran naskahmu.' },
        { property: 'og:type', content: "website" },
        { property: 'og:url', content: "https://www.guepedia.com/" },
        { property: 'og:title', content: 'Penerbit buku online gratis Indonesia  | Guepedia.com' },
        { property: 'og:description', content: 'Penerbit buku online gratis, anti ribet, dan cepat, puluhan ribu naskah sudah terbit disini, sekarang giliran naskahmu.' },
      ]
    }
  },

  computed: {
    isAddressValid() {
      const address = this.form.address.toLowerCase()
      const hasStreets = address.includes("jl") || address.includes("jalan") || address.includes("komplek") || address.includes("perumahan")
      const rukun = address.includes("rt") || address.includes("cluster") || address.includes("blok") || address.includes("rw")
      return hasStreets && rukun
    },
  },

  methods: {
    // Fetch Provinsi
    async fetchProvinces() {
      try {
        const response = await axios.get(
          "https://kanglerian.github.io/api-wilayah-indonesia/api/provinces.json"
        );
        this.provinces = response.data;
      } catch (error) {
        console.error("Gagal memuat data provinsi:", error);
      }
    },

    // Ketika Provinsi Dipilih
    async onProvinceChange() {
      this.cities = [];
      this.districts = [];
      this.villages = [];
      this.form.city = "";
      this.form.district = "";
      this.form.village = "";

      try {
        const response = await axios.get(
          `https://kanglerian.github.io/api-wilayah-indonesia/api/regencies/${this.form.province}.json`
        );
        this.cities = response.data;
      } catch (error) {
        console.error("Gagal memuat data kota/kabupaten:", error);
      }
    },

    // Ketika Kota Dipilih
    async onCityChange() {
      this.districts = [];
      this.villages = [];
      this.form.district = "";
      this.form.village = "";

      try {
        const response = await axios.get(
          `https://kanglerian.github.io/api-wilayah-indonesia/api/districts/${this.form.city}.json`
        );
        this.districts = response.data;
      } catch (error) {
        console.error("Gagal memuat data kecamatan:", error);
      }
    },

    // Ketika Kecamatan Dipilih
    async onDistrictChange() {
      this.villages = [];
      this.form.village = "";

      try {
        const response = await axios.get(
          `https://kanglerian.github.io/api-wilayah-indonesia/api/villages/${this.form.district}.json`
        );
        this.villages = response.data;
      } catch (error) {
        console.error("Gagal memuat data kelurahan:", error);
      }
    },

    // Menghasilkan Alamat Lengkap
    getFullAddress() {
      return `${this.form.address}, ${this.getVillageName()}, ${this.getDistrictName()}, ${this.getCityName()}, ${this.getProvinceName()}`;
    },

    // Method untuk mengambil nama provinsi
    getProvinceName() {
      const province = this.provinces.find(prov => prov.id === this.form.province);
      return province ? province.name : '';
    },

    // Method untuk mengambil nama kota
    getCityName() {
      const city = this.cities.find(city => city.id === this.form.city);
      return city ? city.name : '';
    },

    // Method untuk mengambil nama kecamatan
    getDistrictName() {
      const district = this.districts.find(district => district.id === this.form.district);
      return district ? district.name : '';
    },

    // Method untuk mengambil nama kelurahan
    getVillageName() {
      const village = this.villages.find(village => village.id === this.form.village);
      return village ? village.name : '';
    },
    
    async Register() {
      if(!this.isAddressValid) {
        Swal.fire({
          icon: "error",
          title: "Validasi Gagal",
          text: "Alamat lengkap harus mencantumkan nama Jalan, RT, dan RW. Agar naskah mu di terima",
          confirmButtonText: "OK",
        })
        return
      }

      this.form.address = this.getFullAddress();

        try {
          const response = await axios.post("register", {
            nama_lengkap: this.form.name,
            nama_pena: this.form.pena,
            nomor_kontak: this.form.kontak,
            provinsi: this.form.province,
            kota: this.form.city,
            kecamatan: this.form.district,
            kelurahan: this.form.village,
            alamat_lengkap: this.form.address,
            email: this.form.email,
            password: this.form.password,
            password_confirmation: this.form.password_confirmation,
            facebook: this.form.facebook,
            instagram: this.form.instagram,
          })
          Swal.fire({
            icon: "success",
            title: "Pendaftaran Berhasil!",
            text: "Akun Anda telah terdaftar. Verifikasi email kamu dulu ya sebelum login untuk melanjutkan.",
            confirmButtonText: "Login Sekarang",
          }).then(() => {
              this.$router.push("/login")
          })
        } catch (error) {
          this.$router.push('/login');

          this.openNotification(
            6000,
            "top-right",
            "success",
            "Registration Success",
            response.data.message
          );
        }
    },
  },
};
</script>

<!-- account_id = bjkrvdg7

id = 9042

Some cloud vendors (such as Google, Amazon) close port 25 by default, and you need to contact the vendor to open port 25 before you can use the post office service normally

BREVO_API_KEY=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

brevo-code:72e85d6f4d5be75f7d22daf2dc4d1b22

_dmarc dns guepedia lama
type = txt
name = _dmarc
value = "v=DMARC1;p=quarantine;rua=mailto:admin@guepedia.com"

MAIL_MAILER=smtp
MAIL_HOST=mail.guepedia.com
MAIL_PORT=587
MAIL_USERNAME=no-reply@guepedia.com
MAIL_PASSWORD=GueMailServer2
MAIL_ENCRYPTION=tls
MAIL_FROM_ADDRESS=no-reply@guepedia.com
MAIL_FROM_NAME='Notifikasi dari Guepedia' -->