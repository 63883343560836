<template>
  <div class="checkout-info">
    <div class="address-data">
      <div class="cart-title">Alamat Pengiriman</div>
      <hr class="price-line" />

      <div class="address-detail">
        <div class="receiver d-flex">
          <div class="receiver-detail me-3" v-if="alamat">
            <span class="fw-bold">{{ alamat.nama_penerima }}</span> ({{
              alamat.nomor_kontak
            }})
          </div>
          <button
            type="button"
            class="btn btn-light edit-address-btn fw-bold"
            @click="showAddressModal"
          >
            <span v-if="alamat">Ubah Alamat Pengiriman</span>
            <span v-else>Atur Alamat Pengiriman</span>
          </button>
        </div>

        <div class="address" v-if="alamat">
          {{ alamat.alamat_lengkap }}<br />
          {{ alamat.nama_kecamatan }} - {{ alamat.nama_kota }} -
          {{ alamat.nama_provinsi }} {{ alamat.kode_pos }}
        </div>
      </div>
    </div>

    <hr />

    <div class="shipping-detail">
      <div class="cart-title">Kurir Pengiriman</div>
      <hr class="price-line" />

      <div class="shipping">
        <div class="row align-items-center" v-if="ongkir[0]">
          <div class="col-md-6 col-lg-6 col-12 col-sm-12">
            <div class="shipping-item">
              <div class="fw-bold">
                {{ ongkir[0].service_name }} {{ ongkir[0].service }}
              </div>
              <div class="shipping-service">
                {{ ongkir[0].description }} Perkiraan
                {{ ongkir[0].cost[0].etd }} Hari
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-12 col-sm-12">
            <div class="shipping-item fw-bold text-end">
              {{ ongkir[0].cost[0].value | currency }}
            </div>
          </div>
        </div>

        <div class="col-md-3 col-lg-3 col-12 col-sm-12 mt-3">
          <button
            type="button"
            class="btn btn-light edit-address-btn fw-bold"
            @click="getOngkir"
          >
            <span v-if="ongkir[0]">Ubah Jasa Pengiriman</span>
            <span v-else>Pilih Jasa Pengiriman</span>
          </button>
        </div>
      </div>
    </div>

    <hr />

    <div class="cart-title">Data Belanja Anda</div>
    <hr class="price-line" />

    <div class="cart-area my-3">
      <div class="cart-item" v-for="item in cart" :key="item.id_file_naskah">
        <div class="row align-items-center">
          <div class="col-md-1 col-lg-1 col-3 col-sm-3">
            <img
              :src="item.file_cover | cover"
              class="card-img-top product-image"
            />
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="cart-product-title">{{ item.judul }}</div>
          </div>
          <div class="col-md-3 col-lg-3">
            <div class="qty-checkout">
              {{ item.qty }} x {{ item.harga | currency }}
            </div>
          </div>
          <div class="col-md-2 col-lg-2">
            <div class="cart-product-price text-right">
              {{ item.total_price_item | currency }}
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>


    <!-- ongkir modal -->
    <b-modal
      title="Pilih Ongkir"
      ref="ongkirModal"
      hide-footer
      hide-header-close
    >
      <div
        class="shipping-detail"
        v-for="ongkir in data_ongkir"
        :key="ongkir.code"
      >
        <div class="cart-title">{{ ongkir.name }}</div>
        <hr class="price-line" />

        <div
          class="shipping mb-2"
          v-for="cost in ongkir.costs"
          :key="cost.service"
        >
          <div class="row align-items-center">
            <div class="col-md-6 col-lg-6 col-12 col-sm-12">
              <div class="shipping-item">
                <div class="fw-bold">
                  {{ cost.description }} ({{ cost.service }})
                </div>
                <div class="shipping-service">
                  Estimasi sampai : {{ cost.cost[0].etd }} Hari
                </div>
              </div>
            </div>

            <div class="col-md-3 col-lg-3 col-12 col-sm-12">
              <div class="shipping-item fw-bold">
                {{ cost.cost[0].value | currency }}
              </div>
            </div>

            <div class="col-md-3 col-lg-3 col-12 col-sm-12 text-right">
              <button
                v-b-modal.ongkirModal
                type="button"
                class="btn btn-light edit-address-btn fw-bold"
                @click="selectedOngkir(ongkir, cost)"
              >
                Pilih
              </button>
            </div>
          </div>
        </div>

        <hr />
      </div>
    </b-modal>

    <b-modal ref="editAddress" centered scrollable hide-header hide-footer size="lg">
      <edit-address/>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

import EditAddress from "@/components/AccountPage/Account/Modal/EditAddress";
export default {
  components:{
    EditAddress
  },
  data() {
    return {
      alamat: [],
      data_ongkir: [],

      loading: false,
      loadingOngkir: false,
    };
  },
  computed: {
    ...mapGetters(["cart", "ongkir"]),
  },
  methods: {
    ...mapActions(["selectOngkir"]),

    showAddressModal() {
      this.$refs['editAddress'].show();
    },

    getAlamat() {
      this.loading = true;
      axios
        .get("myaddress", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.alamat = response.data.data.alamat;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    selectedOngkir(ongkir, cost) {
      cost["service_courier"] = ongkir.code;
      cost["service_name"] = ongkir.name;

      this.selectOngkir(cost);
      this.$refs["ongkirModal"].hide();
    },

    getOngkir() {
      this.$refs["ongkirModal"].show();
      this.loadingOngkir = true;
      var subdistrict = this.alamat.subdistrict_id;
      var totalWeight = this.cart.reduce((a, b) => a + b.qty * b.berat_buku, 0);

      var formData = new FormData();
      formData.append("destination", subdistrict);
      formData.append("origin", "78");
      formData.append("weight", totalWeight);
      formData.append("originType", "city");
      formData.append("destinationType", "subdistrict");

      axios
        .post("getOngkir", formData, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          console.log(response.data.data.ongkir)
          this.data_ongkir = response.data.data.ongkir;
          console.log(this.data_ongkir);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOngkir = false));
    },
  },

  mounted() {
    this.getAlamat();
    // console.log(localStorage.vuex);
  },
  watch: {
    cart: {
      handler(cart) {
        // console.log(cart);
      },
      deep: true,
    },

    ongkir: {
      handler(ongkir) {
        // console.log(ongkir);
      },
      deep: true,
    },
  },
};
</script>
